/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 45 Light';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 45 Light'), url('NeueHaasDisplayLight.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 46 Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 46 Light Italic'), url('NeueHaasDisplayLightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 15 XXThin';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 15 XXThin'), url('NeueHaasDisplayXXThin.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 16 XXThin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 16 XXThin Italic'), url('NeueHaasDisplayXXThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 25 XThin';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 25 XThin'), url('NeueHaasDisplayXThin.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 26 XThin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 26 XThin Italic'), url('NeueHaasDisplayXThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 35 Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 35 Thin'), url('NeueHaasDisplayThin.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 36 Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 36 Thin Italic'), url('NeueHaasDisplayThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 55 Roman';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 55 Roman'), url('NeueHaasDisplayRoman.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 56 Roman Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 56 Roman Italic'), url('NeueHaasDisplayRomanItalic.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 65 Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 65 Medium'), url('NeueHaasDisplayMediu.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 66 Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 66 Medium Italic'), url('NeueHaasDisplayMediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 75 Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 75 Bold'), url('NeueHaasDisplayBold.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 76 Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 76 Bold Italic'), url('NeueHaasDisplayBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 95 Black';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 95 Black'), url('NeueHaasDisplayBlack.woff') format('woff');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro 96 Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Neue Haas Grotesk Display Pro 96 Black Italic'), url('NeueHaasDisplayBlackItalic.woff') format('woff');
}
