@import '~antd/dist/antd.css';
@import './asset/neue-font/style.css';

body {
  font-family: 'Neue Haas Grotesk Display Pro 45 Light';
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.03em;
  height: auto;
}

.ffb {
  font-family: 'Neue Haas Grotesk Display Pro 65 Medium';
}

.form-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #4b706b;
}

.form-logo {
  width: 130px;
}

.auth-page-container {
  margin-top: 50px;
}

.custom-header {
  height: 88px;
  background-color: #ffffff !important;
  justify-content: space-between;
  display: flex;
  align-items: center;
  /* box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04); */
}

.custom-header .logo {
  width: 70px;
  margin-left: 15px;
  display: inline;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #fff; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

/* .App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.pull-right {
  float: right;
}
/* 
.site-layout-content{
  color: #449674;
  padding-top: 50px;
  font-size: 30px;
} */

/* Academics view css start */
li.ant-menu-item.ant-menu-item-only-child:hover {
  border-bottom: none !important;
}
.heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 28px; */
  letter-spacing: 0.03em;
  text-align: left;
  color: #3a926a;
}

.heading1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 28px; */
  letter-spacing: 0.03em;
  text-align: left;
  color: #4b706b;
}

.go-to-next-academic {
  position: absolute;
  bottom: 20px;
}
.academic-list img {
  /* width: 133px;
	height: 152px; */
  border-radius: 8px;
  float: right;
}
.academic-list .image-container {
  text-align: left;
  display: flex;
  flex-direction: row-reverse;
}
.coursesList .ant-card-body {
  background-color: #f7f7f5;
}
.coursesList .description {
  font-size: 14px !important;
  font-weight: 600 !important;
}
.ant-progress-inner {
  background-color: #fff;
}
.ant-progress-bg {
  background-color: #3a926a;
  animation: none;
  -webkit-animation: none;
}
.ant-progress-status-active .ant-progress-bg::before {
  animation: none;
  -webkit-animation: none;
}

.custom-header .ant-menu {
  border-bottom: 0px;
}
/* Academics view css end */
/* forgot password login start */
.forgot {
  color: #3a926a;
  font-style: normal;
}
/* forgot password login end */
/* button start */
.btn-ipso {
  border-radius: 5px;
  background-color: #449674;
  color: #fff;
}

h1.page-title {
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
  color: #4b706b;
  letter-spacing: 0.03em;
  font-family: 'Neue Haas Grotesk Display Pro 55 Roman';
}
.content-title {
  margin: 25px 0px;
}
.academic-list h2.title {
  font-weight: 600;
  font-size: 22px;
  color: #2c335a;
  font-family: 'Neue Haas Grotesk Display Pro 55 Roman';
  line-height: 28px;
  letter-spacing: 0.03em;
  word-wrap: break-word;
}
.academic-list .description {
  font-size: 16px;
  color: #4b706b;
  opacity: 0.8;
  line-height: 32px;
  font-style: 'Neue Haas Grotesk Display Pro 45 Light';
  font-style: normal;
  font-weight: 500;
}
.anticon svg {
  display: inline-block;
  color: #1b2250;
}
/* button stop */

.ant-layout-content.ipso-content {
  padding: 50px;
  min-height: calc(100vh - 160px);
}

.custom-anchor {
  color: #449674;
}

.custom-anchor:hover {
  color: #0a6e45;
}

.ant-form-item-label label {
  font-size: 16px;
  font-weight: 300 !important;
  color: #4a5568;
  line-height: 19px;
}

.theme-green {
  color: #3a926a;
}

.theme-button {
  background-color: #449674 !important;
  color: #fff !important;
  border-radius: 5px;
  height: 50px;
  font-size: 16px;
  line-height: 19px;
}
.theme-button:hover {
  background-color: #449674;
  color: #fff;
}

.theme-input {
  border-radius: 4px;
}

.form-subtitle {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #2d3748;
}

.forgot-password {
  color: #3a926a;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px;
}
.forgot-password:hover {
  color: #3a926a;
}

.academy-detail-tab .ant-tabs-nav {
  margin-bottom: 0px;
}
.ant-tabs-tab-btn {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: normal;
}

@media screen and (max-width: 480px) {
  .ant-layout-content.ipso-content {
    padding: 10px 10px 10px 10px;
  }

  header .availablityIndicator {
    right: 14px;
  }

  /* .page-title{
    display: none;
  } */
}
.ant-form-item-control-input-content .ant-checkbox-wrapper > span {
  margin-right: 6px;
}
.ant-checkbox-wrapper span,
.line-text {
  font-family: 'Neue Haas Grotesk Display Pro 55 Roman';
  font-weight: 500;
  color: #2d3748;
}
.ant-form-item-explain-error {
  font-family: 'Neue Haas Grotesk Display Pro 55 Roman';
  font-weight: 300;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  font-size: 20px;
  font-weight: 600;
  color: #4b706b;
  top: 0px;
  border-bottom: none !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #3a926a;
  border-bottom: none !important;
}
.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-dropdown-trigger.ant-dropdown-rtl.ant-dropdown-link {
  margin: 0px 15px;
}
.ant-dropdown-trigger.ant-dropdown-rtl.ant-dropdown-link .anticon.anticon-phone {
  margin: 0px 10px;
}
.ant-dropdown-trigger.ant-dropdown-link {
  color: #595959;
  font-family: 'Neue Haas Grotesk Display Pro 45 Light';
  font-weight: 300;
  margin: 0px 15px;
}
.ant-dropdown-trigger.ant-dropdown-link .anticon {
  margin: 0px 5px;
}
.ant-card.academic-list {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.ant-dropdown.ant-dropdown-placement-bottomCenter {
  min-width: 32px;
  left: 1239px;
  top: 80px !important;
}
.ant-avatar-string {
  font-family: 'Neue Haas Grotesk Display Pro 45 Light';
  font-weight: 400;
}
.ant-avatar.ant-avatar-circle {
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-size: 18px;
}
/*******************/
button.ant-btn.ant-dropdown-trigger {
  width: 71px;
  height: 32px;
  border: 1px solid #626674;
  border-radius: 4px;
  box-sizing: border-box;
  color: #626674;
}
.ant-notification {
  font-family: 'Neue Haas Grotesk Display Pro 45 Light';
  font-weight: 400;
}
.mobile-menu {
  display: none;
}
.publishBtn {
  margin: 24px 10%;
  float: right;
}
/* iPads (landscape) ----------- */
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .academic-list h2.title {
    font-size: 20px;
  }
  .academic-list img {
    width: 100%;
    height: 152px;
    border-radius: 8px;
  }
  .mobile-menu {
    display: none;
  }
  .desktop-menu {
    display: block;
  }
}

@media all and (device-width: 768) and (device-height: 1024) and (orientation: portrait) {
  .mobile-menu {
    display: block;
  }
  .desktop-menu {
    display: none;
  }
}
/* Extra Small Devices, Phones */
@media screen and (max-width: 480px) {
  .academic-list h2.title {
    font-size: 20px;
  }
  .academic-list img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  .ant-layout-header {
    padding: 10px;
  }
  .custom-header .ant-menu {
    margin-left: 30px !important;
  }
  header .ant-avatar.ant-avatar-circle {
    margin-right: 15px;
  }
  .ant-dropdown.ant-dropdown-placement-bottomCenter {
    left: 215px !important;
  }
  .academy-course-img img {
    height: auto;
  }
  /* .ant-layout-header.custom-header div.left {
		display: none !important;
	} */
  /* .ant-layout-header.custom-header div.right button.ant-dropdown-trigger {
		order: 3;
	}
	.ant-layout-header.custom-header div.right a.ant-dropdown-trigger {
		order: 1;
	}
	.ant-layout-header.custom-header div.right span.ant-dropdown-trigger {
		order: 2;
	} */
  header .ant-dropdown-trigger.ant-dropdown-link {
    display: none;
  }
  /*.mobile-menu {
		display: block;
	}
	.desktop-menu {
		display: none;
	}*/
  .custom-header .logo {
    width: 125px;
    margin-left: 10px;
    display: inline;
  }
  .course-progress button {
    text-align: left;
    margin: 5px !important;
  }
  .fullWidthGrey.academy-detail-div {
    padding: 30px 30px 0px 30px;
  }
  .tabContentPadding.academy-tabcontent {
    padding: 25px 30px;
    margin: inherit;
  }
  .academy-detail-tab .ant-tabs-tab-btn {
    font-size: 14px;
  }
  .academy-detail-tab .ant-tabs-tab {
    margin: 0 11px 0 0;
  }
  .academyListing .ant-list-lg .ant-list-item {
    padding: 6px 10px;
  }
  .publishBtn {
    margin: 24px auto;
    float: left;
  }
  .academyItem .info {
    word-wrap: break-word;
    width: 60%;
  }
  .enroll-pos-top {
    float: left !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.ant-menu-horizontal > .ant-menu-item a {
  color: #3a926a;
}
.ant-menu-submenu.ant-menu-submenu-popup .ant-menu-item {
  font-size: 16px;
  font-family: 'Neue Haas Grotesk Display Pro 45 Light';
  font-weight: 400;
}

.availablityIndicator {
  background-color: grey;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  position: relative;
  bottom: 10px;
  right: 0px;
  display: block;
  float: right;
}

.availablityIndicator.active {
  background-color: green;
}
.availablityIndicator.inactive {
  background: #fff;
  border: 2px solid gray;
}

.topProfileMenu .ant-avatar.ant-avatar-circle {
  display: block;
  margin-top: 8px;
}

.academyUsersList .metaInfo {
  padding-left: 20px;
}
/*********/
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3a926a;
  border-color: #3a926a;
  border-radius: 2px;
}
.ant-form-item-has-error .anticon svg {
  color: #f5222d;
}
.ant-popover-inner-content .anticon.anticon-check svg {
  color: green;
}
.ant-popover-inner-content .anticon.anticon-close svg {
  color: red;
}
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 45px;
}
.fullWidthGrey {
  padding: 50px 50px 0px 50px;
}
.tabContentPadding {
  padding: 25px 50px;
}
.ant-breadcrumb {
  margin-bottom: 15px;
}
.courseProgress .ant-progress-inner {
  background-color: #f5f5f5;
}
.course-progress button {
  background: #f7f7f5;
  border-radius: 48px;
  padding: 9px 32px;
  height: auto;
}
.course-progress {
  margin-bottom: 15px;
}
.courseTeam .ant-list-split .ant-list-item {
  border: none;
}
.courseTeam .ant-list-item-meta-title {
  font-weight: 500;
  font-family: 'Neue Haas Grotesk Display Pro 55 Roman';
}
.courseTeam .ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 1.5715;
}
.class-room-block {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 15px;
}
.class-room-block h3 {
  color: #2c335a;
  font-size: 22px;
  font-weight: 600;
}
.class-room-block p,
.class-room-block .schedule span {
  color: #2c335a;
  font-family: 'Neue Haas Grotesk Display Pro 45 Light';
  font-weight: 400;
  font-size: 14px;
}
.class-room-block a {
  color: #3a926a;
  font-family: 'Neue Haas Grotesk Display Pro 45 Light';
  font-weight: 400;
}
.ant-btn.join-class-button {
  background: #3a926a;
  color: #fff;
  border: 1px solid #3a926a;
  border-radius: 4px;
  padding: 5px 30px;
  line-height: 20px;
}
.class-room-block .sub-title {
  color: #2c335a;
  font-size: 16px;
  font-weight: 600;
}
.class-room-block .schedule p {
  margin-bottom: 0.5em;
}
.course-comments .content-title.green {
  margin: 20px 0px 0px 0px;
  font-weight: 600;
  font-size: 16px;
}
.ant-popover-inner .courseLeader {
  margin-bottom: 15px;
}
.ant-popover-inner .course-progress button {
  padding: 5px 10px;
  height: auto;
  font-size: 12px;
}
.learnModule .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.learnModule .anticon.anticon-check-circle svg {
  fill: #3a926a !important;
}
.learnModule .ant-collapse-header {
  font-size: 16px;
}
.learnModule .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  font-size: 14px;
  top: 18px;
}
.learnModule .ant-collapse-content-box p {
  background: #eaf2ef;
  padding: 8px 25px;
  font-weight: 400;
  cursor: pointer;
}
.home-work {
  display: inline-block;
  margin-left: 15px;
  font-size: 12px;
  color: #4b706b;
}
.learnModule .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  display: inline-block;
  margin-left: 10px;
  float: none;
}
.switch-module {
  text-align: right;
  font-size: 12px;
  color: #595959;
  margin: 15px;
}
.course-info h2,
.course-info .course-button-edit,
.courseTeam h2,
.courseTeam .course-button-edit {
  display: inline-block;
}
.course-info .course-button-edit,
.courseTeam .course-user-add {
  color: #3a926a;
  border-color: #3a926a;
  border-radius: 4px;
  display: inline-block;
  margin-left: 30px;
}
.course-info .course-button-edit svg,
.courseTeam .course-user-add svg {
  color: #3a926a;
}
.anticon.anticon-delete {
  font-size: 16px;
}
/***************/
.ant-card-rtl .anticon.anticon-arrow-right svg {
  transform: rotate(180deg);
}
.custom-header button.ant-btn.ant-btn-rtl.ant-dropdown-rtl {
  width: 70px !important;
}
.ant-btn.enroll-btn {
  background: #3a926a;
  color: #fff;
  border-color: #3a926a;
  border-radius: 4px;
}
.enroll-pos-top {
  float: right;
  margin-top: 10px;
}
.ant-btn.enroll-btn:hover {
  color: #fff;
}
.mobile-menu {
  text-align: center;
  margin: auto;
}
.mobile-menu .ant-col {
  padding: 10px;
}
.mobile-menu a {
  color: #3a926a;
  font-size: 16px;
  text-align: center;
  margin: auto;
}
.mobile-menu .ant-divider.ant-divider-horizontal {
  margin: 0px;
  border: 1px solid #39bd81;
  opacity: 0.2;
}
.course-info .ant-row-middle {
  align-items: normal;
}
.team-member-add .ant-form-item-control-input-content label {
  display: block;
  margin: 5px 0px;
}
.team-member-add .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #fff;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #3a926a;
}
.team-member-add .ant-checkbox-wrapper span {
  font-family: 'Neue Haas Grotesk Display Pro 45 Light';
  font-weight: 400;
  color: #2d3748;
}
.coursesDetail p {
  word-wrap: anywhere;
}
.view-enroll {
  color: #3a926a;
  border: 1px solid #3a926a;
  padding: 4px 15px;
  border-radius: 4px;
}
.ant-btn.join-class-button:hover,
.ant-btn.join-class-button:focus {
  border-color: #3a926a;
  color: #fff;
}
.ant-radio-checked::after {
  border: 1px solid #3a926a;
}
.ant-radio-inner::after {
  background-color: #3a926a;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #3a926a;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #3a926a;
}
.profile-title h1 {
  font-size: 28px;
  margin-bottom: 0px;
}
.profile-avatar .ant-avatar-string {
  font-size: 28px;
  font-weight: 500;
  font-family: 'Neue Haas Grotesk Display Pro 55 Roman';
}
.profile-title p {
  color: #595959;
  font-family: 'Neue Haas Grotesk Display Pro 45 Light';
  font-weight: 300;
}
li.ant-dropdown-menu-item a {
  color: #595959;
}
.groupMembers {
  color: #595959;
  font-family: 'Neue Haas Grotesk Display Pro 45 Light';
  font-weight: 400;
  font-size: 16px;
}
.academy-tabcontent .site-layout aside.ant-layout-sider-dark {
  max-width: 16px !important;
  min-width: 16px !important;
  width: 32px !important;
}

.callring {
  -webkit-animation: vibrate 2s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  animation: vibrate 2s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 300px;
  perspective: 300px;
}

@keyframes vibrate {
  0%,
  2%,
  4%,
  6%,
  8%,
  10%,
  12%,
  14%,
  16%,
  18% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  1%,
  3%,
  5%,
  7%,
  9%,
  11%,
  13%,
  15%,
  17%,
  19% {
    -webkit-transform: translate3d(1px, 0, 0);
    transform: translate3d(1px, 0, 0);
  }
  20%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
