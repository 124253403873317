.ipso-editor {
  .ql-snow {
    .ql-editor {
      p,
      li {
        font-size: 14px;
      }
      blockquote,
      pre.ql-syntax {
        font-weight: normal;
        font-size: 12px;
      }
    }
  }

  .ql-toolbar .ql-formats .ql-indent {
    padding: 3px 5px !important;
  }
}
