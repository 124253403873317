@import '~antd/lib/style/index';
@import './asset/neue-font/style.css';

$greencolor: #3a926a;
$greycolor: #626674;
$greenHoverColor: #41ad7d;

.container {
  margin: 0px auto;
}
h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.17em;
}
@media (max-width: 360px) {
  .ant-modal-body {
    .ant-form-vertical .ant-btn {
      width: 100%;
    }
  }
}
@media (max-width: 375px) {
  .custom-header {
    .logo {
      display: none;
    }
    .desktop-menu.ant-menu-root.ant-menu-horizontal {
      margin-left: 2px !important;
    }
    .ant-menu-submenu-horizontal.ant-menu-overflowed-submenu {
      margin: 0 5px;
    }
    .ant-btn.ant-dropdown-trigger {
      padding-left: 5px !important;
      padding-right: 5px !important;
      width: 50px !important;
    }
    .ant-switch {
      padding-left: 8px !important;
      padding-right: 22px !important;
    }
    .ant-switch-inner {
      margin: 0 0px 0 15px !important;
    }
  }
}
@media (max-width: 414px) {
  .schedule-btns button.ant-btn {
    width: 100%;
  }
  .invite-student-btn .ant-btn {
    width: 100%;
  }
  .courseTeam .transparentButton {
    margin: 5px !important;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .availablityIndicator {
    right: 18px;
  }

  /*** user view header***/
  /**.ant-layout-header.header-user div.left {
        display: none !important;
    }**/

  .header-user {
    .ant-dropdown-trigger.ant-dropdown-link {
      order: 1;
      display: block !important;
    }

    .ant-dropdown-trigger.topProfileMenu {
      order: 2;
    }

    div.right button.ant-dropdown-trigger .ant-btn.ant-dropdown-trigger {
      order: 3;
    }

    .ant-dropdown-trigger.topProfileMenu {
      margin: 0;
    }

    .ant-dropdown.ant-dropdown-placement-bottomCenter {
      left: 175px !important;
    }

    div.right {
      width: 100%;
    }
  }

  /*** admin view header***/
  .header-admin {
    .ant-avatar.ant-avatar-circle {
      margin-left: 0px !important;
    }

    .ant-dropdown-trigger.ant-dropdown-link {
      display: block !important;
    }

    .ant-switch-checked .ant-switch-inner {
      margin: 0 0px 0 7px;
    }
  }

  .header-admin .ant-dropdown-trigger label,
  .header-user .ant-dropdown-trigger label {
    display: none;
  }

  .coursesDetail {
    .ant-image {
      float: left;
    }
    div:nth-child(2) {
      text-align: center !important;
    }
    .ql-indent {
      padding-top: 10px;
    }
  }

  .schedule-btns {
    text-align: center;
    button.ant-btn {
      margin: 5px 5px !important;
    }
  }

  h1.page-title {
    font-size: 20px;
    margin-bottom: 0;
  }
  .studentCount .ant-badge-status-dot {
    width: 5px !important;
    height: 5px !important;
    left: 2px;
    top: -5px !important;
  }
  .learnModule .ant-steps-small .ant-steps-item-icon {
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important;
  }
  .learnModule .ant-radio-group.ant-radio-group-solid {
    width: 100% !important;
  }
  .learnModule .step-polls .ant-radio-button-wrapper {
    height: 100% !important;
  }
  .steps-action {
    .ant-btn {
      padding: 4px;
    }
  }
  .courseProgress {
    h2 {
      margin: 10px 0px;
    }
  }
  .academyUsersList .topProfileMenu .availablityIndicator {
    right: 5px;
  }
  .studentCount .ant-badge-status-text {
    font-size: 14px !important;
    margin-left: 4px;
  }
  .ant-tabs-mobile.academy-detail-tab .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab:last-child {
    margin-right: 0px;
  }

  .profileDetails {
    padding: 30px 20px !important;
  }
  .personal-info {
    padding: 20px 20px !important;
  }
  .ql-indent iframe {
    width: 100%;
  }
  .ant-menu-inline-collapsed,
  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
    border: none;
  }
  .learnModule {
    .ant-layout-sider-children ul {
      margin-left: -10px;
    }
    .ant-menu-submenu-title {
      padding-left: 5px !important;
    }
    .ant-layout-sider-collapsed {
      margin-left: -30px;
    }
  }
  .ant-badge-rtl.studentCount .ant-badge-status-dot {
    margin-left: -9px !important;
    margin-right: 4px !important;
  }
  .desktop-menu.ant-menu-rtl.ant-menu-submenu-selected {
    margin-left: 0px !important;
  }
  .courseTeam .transparentButton {
    margin: 5px !important;
  }
  .student-section {
    .availablityIndicator {
      right: 4px;
    }
  }
  .ant-popover-placement-rightTop {
    padding-left: 2rem;
    padding-top: 5rem;
  }
  .ant-dropdown.ant-dropdown-rtl.ant-dropdown-placement-bottomCenter {
    left: 5px !important;
  }
  .custom-header {
    .ant-menu.ant-menu-rtl.ant-menu-light.desktop-menu.ant-menu-root.ant-menu-horizontal {
      margin-left: 0px !important;
    }
    .right .ant-dropdown-rtl {
      margin-left: 0px;
      .ant-avatar-circle {
        margin-left: 0px !important;
      }
    }
    .ant-switch {
      margin-left: 8px !important;
      margin-right: 8px !important;
    }
    .left .desktop-menu.ant-menu-rtl .ant-menu-submenu {
      margin-left: 5px;
    }
  }
  .user-academy-details,
  .users-btn-block .ant-btn {
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 575px) {
  .academy-tabcontent {
    margin: 0px;
  }
  h1.page-title {
    font-size: 24px;
    margin-bottom: 0;
  }
  .courseTeam .transparentButton {
    margin: 5px !important;
  }
}

.custom-header {
  .ant-menu-submenu > .ant-menu-submenu-title::after {
    content: '|||' !important;
    color: #000 !important;
    font-size: 15px;
  }

  .ant-menu-submenu span {
    display: none !important;
  }

  .ant-menu-submenu-title {
    transform: rotate(90deg) !important;
  }

  .mobile-menu-toggle {
    display: none;
  }
}

@media (max-width: 760px) {
  .header-admin .ant-dropdown-trigger label,
  .header-user .ant-dropdown-trigger label,
  .header-admin .ant-menu-item,
  .header-user .ant-menu-item {
    display: none;
  }
  .custom-header {
    padding: 0 18px;
    .ant-menu-submenu > .ant-menu-submenu-title::after {
      content: '|||' !important;
      color: #000 !important;
      font-size: 15px;
    }

    .ant-menu-submenu span {
      display: none !important;
    }

    .ant-menu-submenu-title {
      transform: rotate(90deg) !important;
    }

    .desktop-menu,
    .left > a {
      display: none;
    }

    .mobile-menu-toggle {
      display: flex;
      visibility: visible;
      padding: 4px;
      border: none;
      transform: rotate(90deg);
      margin-left: 12px;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      margin-left: 24px;

      &:hover,
      &:active,
      &:focus {
        color: rgba(0, 0, 0, 0.85);
      }

      span {
        font-size: 20px;
      }
    }
  }

  .header-admin {
    .ant-switch {
      margin-right: 10px;
    }

    .langSelection {
      padding-left: 10px;
      padding-right: 10px;
    }

    .ant-avatar.ant-avatar-circle {
      margin-left: 10px;
    }
  }

  .admin-learn-module .ant-collapse-ghost,
  .learn-sortable .ant-collapse-ghost {
    width: 88% !important;
  }
  .ant-list-item.learn-sortable .ant-collapse-extra {
    right: 30px;
  }
  .admin-learn-module .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow,
  .learn-sortable .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 18px !important;
  }
  .admin-learn-module .module-steps,
  .learn-sortable .module-steps {
    width: 87% !important;
  }
  .admin-learn-module .ant-collapse-content-box p,
  .learn-sortable .ant-collapse-content-box p {
    padding: 10px 2px !important;
  }

  .module-steps .listMenu .ant-menu-submenu {
    margin: 0px 5px !important;
  }
  .learn-sortable .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 5px;
    padding-right: 5px;
  }
  .academyUsersList .ant-form-rtl .searchField {
    margin-right: 0px;
  }
  .ant-collapse-rtl .ant-collapse-extra {
    float: left;
    right: 100% !important;
  }
  .ant-list-rtl .learn-sortable {
    padding: 6px !important;
  }
  .ant-popover-placement-top {
    left: 20px !important;
  }
  .ant-popover-placement-top .ant-popover-inner-content div {
    width: 300px !important;
  }
  .tabContentPadding {
    padding: 25px 18px;
  }
  .fullWidthGrey {
    padding: 25px 18px 0px 18px;
  }
  .academic-list {
    .ant-card-body {
      padding: 15px;
    }
  }

  .publishBtn {
    margin: 24px 30px 24px 0px !important;
  }
  .buddy-buttons {
    text-align: center !important;
  }

  .ant-tabs-dropdown-placement-bottomLeft,
  .ant-dropdown-placement-bottomLeft {
    left: 220px !important;
  }
}

@media (min-width: 576px) {
  .container {
    width: 570px;
  }

  h1.page-title {
    font-size: 24px;
    margin-bottom: 0;
  }
}
@media (max-width: 762px) {
  .publishBtn {
    margin: 24px 0px !important;
    float: left !important;
  }
}
@media (min-width: 760px) {
  .container {
    width: 760px;
  }
  .academyItem {
    .info {
      width: 60%;
    }
  }
  .emailSearchField {
    width: 63% !important;
    float: right !important;
  }
}

@media (min-width: 992px) {
  .container {
    width: 990px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1190px;
  }
  .publishBtn {
    margin: 24px 35px !important;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1390px;
  }
}

.bgWhite {
  background-color: #fff;
}

.green {
  color: $greencolor;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-ink-bar,
.ant-tabs-tab:hover,
.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-item:hover,
.ant-btn:hover,
.ant-btn:focus {
  color: $greencolor;
}

.ant-tabs-ink-bar {
  background-color: $greencolor;
}

.a:hover,
.ant-menu-item a:hover,
.ant-breadcrumb-link a:hover {
  color: $greenHoverColor !important;
}

.ant-spin-dot-item {
  background-color: $greencolor;
}

.ant-btn-primary,
.publishBtn:hover {
  color: #fff !important;
}

// Academy Sortable List
.academyListing {
  width: 100%;
}

.draggableHanle {
  cursor: move;
  font-size: 24px;
  width: 30px;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.academyItem {
  background-color: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  width: 100%;
  padding: 20px 20px;

  .info {
    float: left;
    color: $greencolor;
  }

  .infoSubtitle {
    color: $greencolor;
    margin: 8px 20px;
    text-transform: capitalize;
  }

  h2 {
    color: #2c335a;
    font-weight: 600;
  }

  .ant-image {
    float: right;

    img {
      border-radius: 10px;
    }
  }
}

.ant-list-lg .ant-list-item {
  padding: 6px 12px;
}

.ant-dropdown-trigger {
  cursor: pointer;
}

.greyBg {
  background-color: #f7f7f5;
}

.ant-empty {
  margin: 0px auto;
}

.listMenu {
  float: right;
  border: none;

  .ant-menu-submenu {
    margin: 0px 0px 0px 14px !important;
    font-size: 24px;
  }

  .anticon {
    font-size: 22px;
    margin: 0px;
    top: -8px;
    position: relative;
  }
}

.listSubMenuItems.ant-menu-item-selected {
  color: inherit;
  background-color: white !important;
}

/* default modal design */
.ant-modal-title {
  font-size: 22px;
  font-weight: 600;
}

.ant-modal-header {
  border-bottom: 0px;
}

.ant-modal-content {
  padding: 22px 18px;
}

/* default button colors */
.ant-btn {
  border-radius: 4px;
}

.ant-btn-primary {
  background: $greencolor;
  border-color: $greencolor;
  border-radius: 4px;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: $greenHoverColor;
  border-color: $greenHoverColor;
}

.ant-btn:hover,
.ant-btn:focus {
  border-color: $greencolor;
}

.ant-switch-checked {
  background: $greenHoverColor;
}

.ant-btn-dangerous {
  color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}

.ant-btn.transparentButton {
  border-color: $greencolor;
  color: $greencolor;

  svg {
    color: $greencolor;
  }
}

.ant-btn.transparentButton:hover,
.ant-btn.transparentButton:focus {
  background: $greencolor !important;
  color: #fff;

  svg {
    color: #fff;
  }
}

/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

.deleteConfirmModal {
  .ant-modal-content {
    background-color: #fff8f7;
    text-align: center;
  }

  .ant-modal-confirm-title {
    color: #d11620;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-body {
    padding: 32px 14px 24px;
  }
}

.profileViewHead {
  text-align: center;
  margin-bottom: 24px;

  .topProfileMenu {
    display: inline-block;
  }

  .ant-avatar.ant-avatar-circle {
    width: 75px;
    height: 75px;
    line-height: 75px;
    font-size: 34px;
  }

  h2 {
    color: $greencolor;
    margin: 0px;
    font-weight: 600;
  }

  .subtitle {
    color: grey;
  }
}

.fullHeight {
  height: 100vh;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img {
  width: auto;
  max-width: 60px;
}

.header-admin {
  .ant-btn.ant-dropdown-trigger {
    box-shadow: none !important;
    border-top: 0px !important;
    border-bottom: 0px !important;
    border-right: 1px solid #dbdbdb !important;
    border-left: 1px solid #dbdbdb !important;
    padding-left: 18px;
    width: 80px;
    border-radius: 0px !important;
  }
}

.header-user {
  .left {
    justify-content: space-between;

    .desktop-menu {
      margin-left: 20px;
    }
  }

  .ant-btn.ant-dropdown-trigger {
    box-shadow: none !important;
    border-top: 0px !important;
    border-bottom: 0px !important;
    border-right: 1px solid #dbdbdb !important;
    border-left: 0px !important;
    padding-left: 18px;
    width: 80px;
    border-radius: 0px !important;
  }

  .ant-btn.ant-btn-rtl.ant-dropdown-trigger {
    box-shadow: none !important;
    border-top: 0px !important;
    border-bottom: 0px !important;
    border-left: 1px solid #dbdbdb !important;
    border-right: 0px !important;
    padding-left: 18px;
    padding-right: 7px;
    width: 80px;
    border-radius: 0px !important;
  }
}

@media (max-width: 991px) {
  .custom-header {
    .ant-dropdown-trigger {
      label {
        display: none;
      }
    }
  }
}

//Chime Integration

.chimeIntegration {
  height: 100vh;

  .chimeControls {
    position: fixed;
    bottom: 0px;
    margin: 0px auto;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 567px) {
  .chimeControls {
    text-align: unset !important;
  }
}

//End chime--------

.groupItem {
  background-color: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  width: 100%;
  padding: 20px 20px;
  display: inline-block;
  margin-bottom: 10px;

  .info {
    color: $greycolor;
    float: left;
  }

  .infoSubtitle {
    margin: 8px 20px;
  }

  h2 {
    color: $greencolor;
    font-weight: 600;
  }
}

.learnModule {
  .ant-layout-sider-has-trigger {
    flex: 0 0 200px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
  }

  .ant-layout-sider {
    background: #fff;
  }

  .ant-layout {
    background: #fff;
  }

  .ant-layout-sider-trigger {
    background: rgb(240, 242, 245);
    width: 250px !important;
    position: relative;
  }

  .ant-menu-submenu-title,
  .ant-menu-submenu-title:hover {
    color: #4b706b;
    font-size: 18px;
  }

  .ant-menu-submenu.ant-menu-submenu-inline {
    box-shadow: inset 0px -1px 0px #d9d9d9;
    margin: 0px 6px;
  }
  .ant-menu-inline {
    border-right: none;
  }
  .ant-menu-inline.ant-menu-rtl {
    border-left: none;
  }
  .ant-menu-item.ant-menu-item-only-child {
    font-size: 16px;
    font-family: 'Neue Haas Grotesk Display Pro 45 Light';
    font-weight: 400;
    border-left: 3px solid #fff;
  }

  .ant-menu-item.ant-menu-item-only-child:hover,
  .ant-menu-item.ant-menu-item-only-child.active,
  .ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
    background: #eaf2ef;
    border-radius: 2px;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #3a926a;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #3a926a;
    border-color: #3a926a;
  }

  .anticon.anticon-check.ant-steps-finish-icon svg {
    color: #3a926a;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #3a926a;
  }

  .ant-steps-small .ant-steps-item-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .step-polls h4 {
    font-size: 16px;
    font-weight: 600;
    white-space: pre-wrap;
    padding-bottom: 15px;
  }

  .step-polls .ant-radio-button-wrapper {
    display: block;
    margin-bottom: 10px;
    font-family: 'Neue Haas Grotesk Display Pro 45 Light';
    font-weight: 400;
    border-radius: 3px;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #3a926a;
    background: #fff;
    border-color: #3a926a;
  }

  .ant-radio-group.ant-radio-group-solid {
    width: 50%;
  }

  .steps-form .ant-collapse-header {
    font-weight: 500;
    font-size: 14px;
  }
}

.admin-learn-module,
.learn-sortable {
  .anticon.anticon-plus {
    border: 1px solid #3a926a;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
  }

  .anticon.anticon-plus svg {
    color: #3a926a !important;
    font-size: 12px;
  }

  .anticon.anticon-check-circle svg {
    fill: #3a926a !important;
  }

  .ant-collapse-header {
    font-size: 16px;
    color: #3a926a !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    font-size: 14px;
    top: 18px;
  }

  .ant-collapse-content-box p {
    padding: 10px 25px;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 0px;
  }

  .home-work {
    display: inline-block;
    margin-left: 15px;
    font-size: 12px;
    color: #4b706b;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    display: inline-block;
    margin-left: 10px;
    float: none;
    position: absolute;
    top: 9px;
  }

  .ant-collapse-extra .ant-menu {
    background: transparent;
  }

  .module-steps .ant-menu.ant-menu-light.listMenu {
    position: relative;
    top: 7px;
    background: transparent;
  }

  .module-steps p,
  .module-steps .ant-menu.ant-menu-light.listMenu {
    display: inline-block;
    float: none;
  }

  .steps-form .ant-collapse-header {
    font-weight: 600 !important;
  }

  .steps-form .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #eaf2ef !important;
  }

  .module-steps {
    margin-bottom: 10px;
    width: 100%;
  }

  .module-steps.active,
  .module-steps:hover {
    background: #eaf2ef;
  }

  .anticon.anticon-more {
    font-size: 16px;
  }

  .ant-collapse-ghost {
    width: 100%;
  }

  .draggableHanle {
    margin-top: 2px;
  }

  li.ant-list-item {
    -ms-flex-align: unset;
    align-items: unset;
    justify-content: unset !important;
  }
}

.course-info {
  .site-layout-content {
    margin-bottom: 20px;
  }

  .content-title {
    display: inline;
  }
}

.ant-list-rtl .draggableHanle {
  padding-left: 10px;
}

.course-view-steps {
  .ant-steps-item-icon,
  .ant-steps-item,
  .ant-steps-item-title {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.site-layout-content .ant-col-rtl button.ant-btn {
  float: left !important;
}

.ant-col-rtl button.ant-btn {
  margin-left: 5px !important;
}

.ant-btn-rtl.transparentButton {
  margin-right: 10px;
}

.ant-switch.ant-switch-rtl.ant-switch-checked,
.ant-switch.ant-switch-rtl {
  margin-left: 15px;
}

.mobile-menu a:hover {
  color: #3a926a;
}

.learnModule {
  .collapsed-btns {
    display: inline-block;
  }

  .aside-control {
    padding-left: 30px;
    display: inline-block;
  }

  .ant-menu-inline-collapsed,
  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
    width: 0px !important;
    min-width: 0px !important;
    max-width: 0px !important;
  }
}

/*.anticon.anticon-eye svg {
    fill: #fff !important;
}*/

.ant-col-rtl .ql-snow .ql-picker-label::before,
.ant-list-rtl .ant-list-item-meta-content {
  padding-right: 15px;
}

/* admin student overview*/
.admin-student-overview {
  .student-section {
    cursor: pointer;

    h4 {
      text-transform: capitalize;
    }
  }

  .overview-block {
    h4 {
      font-size: 20px;
      line-height: 32px;
      text-transform: capitalize;
      letter-spacing: 0.03em;
      color: #2c335a;
    }

    .courseProgress {
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 3px;
      }
    }
  }

  .current-module {
    .module div {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
    }

    .homeworks {
      div {
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
      }

      p {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.03em;
        color: #262626;
        margin-left: 10px;
      }
    }
  }

  .mcq-section {
    div {
      font-style: normal;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.03em;
      color: #2c335a;
    }
    .mcq-steps {
      font-weight: 400;
    }
  }

  .back-to-overview {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #262626;
    margin-bottom: 30px;
  }

  .topProfileMenu {
    margin-right: 20px;
  }
  .ant-menu-submenu.ant-menu-submenu-inline.module-name {
    list-style: decimal;
    margin-left: 20px;
  }
  .module-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 46px;
    letter-spacing: 0.03em;
    color: #4b706b;
    box-shadow: inset 0px -1px 0px #d9d9d9;
    padding: 11px 0;

    .ant-menu-submenu-title:hover {
      color: #4b706b;
    }

    .ant-menu-submenu-title {
      padding: 0 !important;
    }

    .status {
      .ant-menu-submenu-title {
        font-size: 20px !important;
        font-weight: 400;
        line-height: 46px;
        letter-spacing: 0.03em;
        color: #4b706b !important;
        opacity: 0.5;

        .ant-menu-submenu-expand-icon,
        .ant-menu-submenu-arrow {
          right: unset;
          left: 120px;
          top: 25px;
        }
      }
    }
  }

  .back-to-overview.ant-col-rtl .anticon-left {
    transform: rotate(180deg);
    margin-left: 5px;
  }
}
.ant-modal.admin-student-overview h4 {
  font-weight: 600;
  font-size: 16px;
  color: #262626;
}
// .ql-editor {
// 	min-height: 200px;
// }
.ant-list-rtl {
  .callButton {
    margin-right: 10px;
  }
  .academyItem .info {
    float: right;
  }
  .academyItem .listMenu,
  .ant-image,
  .ant-btn-rtl.publishBtn {
    float: left;
  }
}
.usersList .ant-tabs-nav {
  margin-bottom: 0px;
}
.ant-btn-rtl.scheduleBtn {
  float: right;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: $greenHoverColor;
}
.ant-form-item-optional {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
  margin-top: 5px !important;
}

.publishBtn .anticon svg,
.ant-btn.ant-btn-primary svg {
  color: #fff;
}
.homework-block .ant-input-number {
  width: 100%;
}

.ant-badge-status-text {
  margin-left: 2px;
}
/*
.academic-list h2.title{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
*/
.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: $greenHoverColor !important;
}
.ant-menu-inline .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-vertical .ant-menu-item::after {
  border-right: 3px solid #3a926a !important;
  left: 0 !important;
  right: unset;
}
.ant-badge-status-dot {
  position: relative;
  top: -5px;
}
.ant-radio-button-wrapper:hover {
  color: #3a926a;
}
h5.hw-title {
  color: #2c335a;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  white-space: pre-wrap;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  display: none;
  background-color: #3a926a;
}
.ant-menu-submenu.ant-menu-submenu-inline.incomplete,
.ant-menu-item.ant-menu-item-only-child.incomplete {
  opacity: 0.4;
  pointer-events: none;
}
.studentCount {
  .ant-badge-status-text {
    font-size: 18px;
  }
  .ant-badge-status-dot {
    width: 7px;
    height: 7px;
    top: -6px;
  }
}

@media (max-width: 991px) {
  .ant-menu.ant-menu-light.desktop-menu.ant-menu-root.ant-menu-horizontal {
    margin-left: 20px !important;
  }
  .header-admin .ant-dropdown-trigger label {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-overflowed-submenu {
    display: none;
  }

  .ant-menu-item.ant-menu-item-only-child.menuitem-overflowed,
  .ant-menu-item.ant-menu-item-only-child.heading,
  .ant-menu-item.ant-menu-item-only-child.heading1 {
    display: inline-block !important;
  }
}
.ant-badge-rtl.studentCount .ant-badge-status-dot {
  margin-left: -7px;
  margin-right: 1px;
}
.publishBtn {
  margin: 24px 15px;
}
.ant-radio-button-wrapper {
  border-left-width: 1px;
  white-space: pre-wrap;
  height: auto;
}
.ant-checkbox-wrapper {
  white-space: pre-wrap;
  height: auto !important ;
  .ant-checkbox + span {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
}

.steps-content {
  pre,
  small {
    font-family: 'Neue Haas Grotesk Display Pro 45 Light';
    font-weight: 400;
    font-size: 12px;
  }
  .homework-submit .ant-btn,
  .ant-upload {
    float: right;
    margin-right: 4px;
  }
  .homework-submit .ant-btn-rtl,
  .ant-upload-rtl {
    float: left;
    margin-left: 4px;
  }

  .step-content-block-viewer {
    padding: 20px 0 30px 0;

    .ql-indent {
      padding: 0 0 16px 0 !important;
      margin: 0;

      &:last-child {
        padding: 0 !important;
      }
      .ql-direction-rtl {
        direction: rtl;
        text-align: inherit;
      }
      li:not(.ql-direction-rtl)::before {
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
      }
      li.ql-direction-rtl::before {
        margin-left: 0.3em;
        margin-right: -1.5em;
      }
      ol li:not(.ql-direction-rtl),
      ul li:not(.ql-direction-rtl) {
        padding-left: 1.5em;
      }
      ol li.ql-direction-rtl,
      ul li.ql-direction-rtl {
        padding-right: 1.5em;
      }
      .ql-indent-1:not(.ql-direction-rtl) {
        padding-left: 3em;
      }
      li.ql-indent-1:not(.ql-direction-rtl) {
        padding-left: 4.5em;
      }
      .ql-indent-1.ql-direction-rtl.ql-align-right {
        padding-right: 3em;
      }
      li.ql-indent-1.ql-direction-rtl.ql-align-right {
        padding-right: 4.5em;
      }
      .ql-indent-2:not(.ql-direction-rtl) {
        padding-left: 6em;
      }
      li.ql-indent-2:not(.ql-direction-rtl) {
        padding-left: 7.5em;
      }
      .ql-indent-2.ql-direction-rtl.ql-align-right {
        padding-right: 6em;
      }
      li.ql-indent-2.ql-direction-rtl.ql-align-right {
        padding-right: 7.5em;
      }
      .ql-indent-3:not(.ql-direction-rtl) {
        padding-left: 9em;
      }
      li.ql-indent-3:not(.ql-direction-rtl) {
        padding-left: 10.5em;
      }
      .ql-indent-3.ql-direction-rtl.ql-align-right {
        padding-right: 9em;
      }
      li.ql-indent-3.ql-direction-rtl.ql-align-right {
        padding-right: 10.5em;
      }
      .ql-indent-4:not(.ql-direction-rtl) {
        padding-left: 12em;
      }
      li.ql-indent-4:not(.ql-direction-rtl) {
        padding-left: 13.5em;
      }
      .ql-indent-4.ql-direction-rtl.ql-align-right {
        padding-right: 12em;
      }
      li.ql-indent-4.ql-direction-rtl.ql-align-right {
        padding-right: 13.5em;
      }
      .ql-indent-5:not(.ql-direction-rtl) {
        padding-left: 15em;
      }
      li.ql-indent-5:not(.ql-direction-rtl) {
        padding-left: 16.5em;
      }
      .ql-indent-5.ql-direction-rtl.ql-align-right {
        padding-right: 15em;
      }
      li.ql-indent-5.ql-direction-rtl.ql-align-right {
        padding-right: 16.5em;
      }
      .ql-indent-6:not(.ql-direction-rtl) {
        padding-left: 18em;
      }
      li.ql-indent-6:not(.ql-direction-rtl) {
        padding-left: 19.5em;
      }
      .ql-indent-6.ql-direction-rtl.ql-align-right {
        padding-right: 18em;
      }
      li.ql-indent-6.ql-direction-rtl.ql-align-right {
        padding-right: 19.5em;
      }
      .ql-indent-7:not(.ql-direction-rtl) {
        padding-left: 21em;
      }
      li.ql-indent-7:not(.ql-direction-rtl) {
        padding-left: 22.5em;
      }
      .ql-indent-7.ql-direction-rtl.ql-align-right {
        padding-right: 21em;
      }
      li.ql-indent-7.ql-direction-rtl.ql-align-right {
        padding-right: 22.5em;
      }
      .ql-indent-8:not(.ql-direction-rtl) {
        padding-left: 24em;
      }
      li.ql-indent-8:not(.ql-direction-rtl) {
        padding-left: 25.5em;
      }
      .ql-indent-8.ql-direction-rtl.ql-align-right {
        padding-right: 24em;
      }
      li.ql-indent-8.ql-direction-rtl.ql-align-right {
        padding-right: 25.5em;
      }
      .ql-indent-9:not(.ql-direction-rtl) {
        padding-left: 27em;
      }
      li.ql-indent-9:not(.ql-direction-rtl) {
        padding-left: 28.5em;
      }
      .ql-indent-9.ql-direction-rtl.ql-align-right {
        padding-right: 27em;
      }
      li.ql-indent-9.ql-direction-rtl.ql-align-right {
        padding-right: 28.5em;
      }
      .ql-align-center {
        text-align: center;
      }
      .ql-video.ql-align-right {
        margin: 0 0 0 auto;
      }
      .ql-align-right {
        text-align: right;
      }
      .ql-align-justify {
        text-align: justify;
      }
    }
  }
}

.buddy-talk {
  background-color: #3a926a;
  width: 100%;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }
}

.buddy-talk {
  .anticon {
    font-size: 25px;
    padding-left: 15px;
  }
  .anticon svg {
    fill: #fff;
  }
  .buddy-details {
    text-align: center;
  }
  .buddy-details span,
  .ant-page-header-heading-title {
    color: #fff;
  }
  .callring {
    color: $greencolor;
  }
  .callreject {
    color: #fff;
    background-color: #3a926a;
    border: 1px solid #fff;
  }
  .ant-avatar-circle {
    background-color: #ffbb96 !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .ant-btn {
    margin: 6px 5px;
  }
  .buddy-buttons {
    text-align: right;
  }
  .title {
    font-size: 16px;
  }
  .sub-title {
    font-size: 14px;
    padding: 5px;
  }
}
.terms-link {
  color: $greencolor;
}
.terms-link:hover {
  color: $greenHoverColor;
}
.callButton .not-available {
  pointer-events: none;
  opacity: 0.3;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #3a926a;
  background-color: #e6f7ff;
}
.ant-collapse-rtl .anticon.anticon-right.ant-collapse-arrow {
  left: 0;
  text-align: left;
  margin-left: -10px !important;
}
.admin-student-overview.ant-menu-rtl {
  border-left: none;
}
.userModal .ant-list-item-meta-title {
  margin: 9px;
}
.ant-btn-rtl .anticon-arrow-right {
  transform: rotate(180deg);
}
.ant-col-rtl .ql-editor p {
  text-align: right;
}
.steps-action .ant-btn-primary[disabled],
.steps-action .ant-btn-primary[disabled] svg {
  color: rgba(0, 0, 0, 0.85) !important;
}
.listSubMenuItems.group-menu-item button {
  background: #fff !important;
  border-color: #fff !important;
  color: rgba(0, 0, 0, 0.85) !important;
  margin: 0px !important;
  font-size: 16px;
  box-shadow: none;
  text-shadow: none;
  text-align: left;
  padding: 0px;
}
.listSubMenuItems.group-menu-item.ant-menu-item-selected button,
.listSubMenuItems.group-menu-item.ant-menu-item-active button {
  color: #3a926a !important;
}

section {
  font-family: 'Neue Haas Grotesk Display Pro 45 Light' !important;
  font-weight: 400;
}
.academyItem .anticon-eye svg,
.coursesDetail .anticon.anticon-eye svg {
  color: #fff;
}
.ql-indent p {
  margin: 0px;
}

.course-view-steps {
  padding: 24px 0px 24px 10px;
}
.course-view-steps .ql-indent {
  padding-top: 10px;
}
.academyUsersList .topProfileMenu .availablityIndicator {
  right: 2px;
}
.schedule {
  span {
    word-wrap: break-word;
  }
  .ant-col.ant-col-12:nth-child(2) {
    padding-left: 20px;
  }
}

.profileDetails {
  padding: 30px 50px;
}
.personal-info {
  padding: 20px 50px;
}
@media (max-width: 480px) {
  .admin-learn-module {
    .ant-collapse-ghost,
    .learn-sortable .ant-collapse-ghost {
      width: 90% !important;
    }
    .draggableHanle {
      width: 20px;
    }
    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      right: 0px;
      left: auto;
      top: 8px !important;
    }
    .ant-list-item.learn-sortable .ant-collapse-extra {
      right: 25px;
    }
    .ant-list .ant-list-item {
      padding: 0px;
    }
  }
  .users-lists {
    .meta-email,
    .callButton {
      margin-top: 10px;
      margin-left: 65px;
      width: 100%;
    }
  }
  .academyUsersList .searchField .ant-row.ant-form-item {
    width: 100%;
  }
  .steps-form .ant-modal-body {
    padding: 10px;
  }
  .steps-form .mcq-block .ant-space.ant-space-horizontal {
    display: block !important;

    .ant-row.ant-form-item {
      margin-bottom: 5px;
    }
    .ant-space.ant-space-horizontal .ant-space-item:nth-child(2),
    .ant-space.ant-space-horizontal .ant-space-item:nth-child(3) {
      display: inline-block;
    }
  }
  .steps-form .mcq-block {
    .ant-space-item:nth-child(2),
    .ant-space-item:nth-child(3) {
      display: inline-block;
    }
  }
}
.ant-menu.ant-menu-light.admin-student-overview {
  border-right: 0px;
}

.ql-indent-1 {
  margin-left: 30px;
}
.ql-indent-2 {
  margin-left: 60px;
}
.ql-indent-4 {
  margin-left: 90px;
}
.ql-indent-5 {
  margin-left: 120px;
}
.ql-indent-6 {
  margin-left: 150px;
}
.ql-indent-1::marker,
.ql-indent-2::marker,
.ql-indent-3::marker,
.ql-indent-4::marker,
.ql-indent-5::marker,
.ql-indent-6::marker {
  font-size: 13px;
}
.ql-indent {
  padding: 15px 0px !important;
}
.ql-indent {
  li {
    padding-bottom: 5px;
  }
  li::marker {
    font-size: 13px;
  }
}
.steps-action .ant-btn-rtl {
  .anticon-right,
  .anticon-left {
    transform: rotate(180deg) !important;
  }
}
.student-section.ant-list-rtl {
  .availablityIndicator {
    float: left;
    right: -4px;
  }
}
.academyUsersList .ant-list-rtl {
  .availablityIndicator {
    float: left !important;
    right: -4px !important;
  }
}
.ql-editor {
  p,
  ol li,
  ul li {
    font-weight: normal;
  }
  li.ql-indent-1 {
    padding-left: 1rem !important;
    padding-bottom: 5px;
  }
  li.ql-indent-2,
  li.ql-indent-6 {
    padding-left: 0.5rem !important;
    padding-bottom: 5px;
  }
  li.ql-indent-3 {
    padding-left: 5.8rem !important;
  }
  li.ql-indent-4 {
    padding-left: 1.8rem !important;
  }
  li.ql-indent-5 {
    padding-left: 1.6rem !important;
  }
}

.ant-layout-sider-zero-width-trigger {
  background: $greencolor;

  .anticon svg {
    color: #fff;
  }
}
.ant-collapse-rtl .anticon-right {
  padding-top: 0px;
}
.ant-row-rtl .academyListing {
  .info {
    float: right;
  }

  .listMenu {
    float: left;
  }
}
.ant-layout-rtl .custom-header .ant-menu-item {
  float: right;
}
a.step-attachments.green:hover {
  color: #3a926a !important;
}
.learnModule {
  .step-polls .ant-checkbox-wrapper {
    display: block;
    margin-bottom: 10px;
    border-radius: 3px;
    span {
      font-family: 'Neue Haas Grotesk Display Pro 45 Light';
      font-weight: 400;
    }
  }
  .ant-checkbox-wrapper {
    position: relative;
    display: inline-block;
    height: 32px;
    margin: 0;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 30px;
    background: #fff;
    border-color: #d9d9d9;
    border-style: solid;
    border-width: 1.02px 1px 1px 0;
    cursor: pointer;
    border-left-width: 1px;
  }
  .ant-checkbox-wrapperr:hover {
    color: #3a926a;
  }
  .ant-checkbox-wrapper > .ant-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .ant-checkbox-group .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled) {
    span {
      color: #3a926a !important;
    }
    background: #fff;
    border-color: #3a926a;
  }
}
.ant-layout-rtl.learnModule {
  .ql-indent-1 {
    margin-right: 30px;
  }
  .ql-indent-2 {
    margin-right: 60px;
  }
  .ql-indent-4 {
    margin-right: 90px;
  }
  .ql-indent-5 {
    margin-right: 120px;
  }
  .ql-indent-6 {
    margin-right: 150px;
  }
}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -5px !important;
}
.invite-student-btn {
  .ant-btn {
    float: left;
  }
}

.courseTeam {
  .site-layout-content .ant-col-rtl .ant-btn-rtl {
    float: right !important;
  }
  .transparentButton {
    margin-top: 28px;
  }
}
.class-room-block .ant-menu-rtl {
  float: left;
  .ant-menu-submenu {
    margin: 0px !important;

    .anticon-more {
      margin-left: 0px !important;
    }
  }
}
.admin-learn-module {
  .ant-collapse-rtl > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: 15px;
    top: 8px;
  }
}
.custom-header .right {
  .ant-dropdown-trigger.ant-dropdown-rtl.ant-dropdown-link {
    padding-left: 15px;
  }
}
.academyListing .ant-menu-rtl .ant-menu-submenu.ant-menu-submenu-horizontal,
.academyListing .ant-menu-rtl .ant-menu-submenu.ant-menu-submenu-horizontal .anticon-more {
  margin-left: 0px !important;
  padding-right: 8px;
}

.academy-tabcontent .ant-menu-inline .ant-menu-submenu-open {
  border-bottom: 1px solid #d9d9d9;
}
.steps-action {
  .nextBtn-col {
    text-align: right;
  }
  .nextBtn-col.ant-col-rtl {
    text-align: left;
  }
}

.chimeControls .ch-control-bar-item-iconButton[aria-label='Unmute'] {
  background: #f7270a;
}
.chimeControls .ch-control-bar-item-iconButton[aria-label='Unmute'] .ch-icon {
  color: #fff;
}
.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.chimeControls .ch-control-bar-item-iconButton {
  background-color: #004ddb;
  color: #fff;
}
.chimeControls .ch-control-bar-item-iconButton[aria-label='Unmute'] {
  background: #f7270a !important;
}
.hidden {
  display: none;
}

body.rtl .ant-notification {
  right: auto !important;
  left: 0px !important;
  direction: rtl !important;
}
body.ltr .ant-notification {
  left: auto !important;
  right: 0px !important;
  direction: ltr !important;
}

body.rtl .ant-notification-rtl .ant-notification-notice-close {
  right: auto !important;
  left: 22px !important;
}
body.ltr .ant-notification-rtl .ant-notification-notice-close {
  left: auto !important;
  right: 22px !important;
}

body.ltr .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px !important;
  margin-left: 25px !important;
  font-size: 16px !important;
}
body.ltr .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-bottom: 4px !important;
  margin-left: 48px !important;
  margin-right: auto !important;
  font-size: 16px !important;
}
@media (max-width: 576px) {
  .course-view-steps .ant-steps-vertical {
    flex-direction: unset;
  }
  .course-view-steps .ant-steps-item-tail {
    display: none !important;
  }

  .course-view-steps .ant-steps-item-title::after {
    position: absolute !important;
    top: 10px !important;
    left: 100% !important;
    display: block !important;
    width: 9999px;
    height: 1px;
    background: #f0f0f0;
    content: '';
  }

  .course-view-steps .ant-steps-item:last-child .ant-steps-item-title::after {
    display: none !important;
  }
}

.z10000 {
  z-index: 10000;
}

.content-block-wrapper {
  .controller-wrapper {
    display: flex;
    justify-content: center;
    padding: 1.2rem 0;

    button {
      margin: 0 0.4rem;
      color: #3a926a;
      border-color: #3a926a;
      svg {
        color: #3a926a;
      }
    }
  }

  .learn-sortable {
    padding: 16px 0;

    .quill,
    .image-upload-wrapper,
    .video-upload-wrapper,
    textarea {
      flex: 1;
      margin: 0 0.8rem;
    }

    .ant-upload-list-item-card-actions-btn {
      display: none;
    }

    .editor-wrapper {
      position: relative;
      max-width: 520px;
      flex: 1;

      .ql-editor {
        max-height: 300px;
        overflow-y: auto;
        min-height: 50px;
      }

      &.editor-expanded .ql-editor {
        max-height: none;
        overflow-y: auto;
      }
    }
  }
}

body {
  > li.learn-sortable {
    > button {
      margin-left: 0.8rem;
    }
    .editor-wrapper {
      position: relative;
      max-width: 520px;
      flex: 1;
    }

    &.has-scroll {
      height: 375px;
      overflow: hidden;

      .editor-wrapper {
        position: relative;
        .ql-editor {
          height: 300px;
          min-height: 50px;
        }
      }
    }
  }
}

.quill-viewer.placeholder .ql-editor p {
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
}

.course-message-form {
  .form-footer {
    display: flex;
    justify-content: space-between;

    .message-attactment-upload {
      display: flex;
      flex-direction: column-reverse;

      .ant-upload-list-text-container {
        margin-bottom: 16px;
      }
    }
  }
}

.browser-permission-not-allow {
  span {
    button {
      background-color: #aeaebc !important;
    }
  }
}

.imprint-privacy-links {
  margin-top: 150px;
  display: flex;
  column-gap: 16px;
  justify-content: center;
  padding: 16px;

  > a {
    font-size: 14px;
    font-weight: 500 !important;
    font-family: 'Neue Haas Grotesk Display Pro 45 Light';
  }
}

.file-download-link {
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  position: relative;

  > a {
    margin: 0 8px;
  }

  .fetching-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-spin-dot svg {
      color: #3a926a;
    }
  }
}

.buddy-talk-popup {
  .popup-prevent-message {
    color: #fff;
    font-weight: 500;
    font-size: 16px;

    .join-buddy-btn {
      color: #3a926a;
      font-weight: 600;
      margin-left: 20px;
    }
  }
}

.ant-menu li.ant-menu-overflow-item::after {
  display: none;
}

.safari-warning {
  margin-bottom: 20px;
  svg {
    color: #faad14;
  }
  .ant-alert-message {
    font-weight: 700;
  }
}
