.step-image-view {
  &.enable-viewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
    img {
      width: 80%;
      height: auto;
    }

    &.height-scale img {
      width: auto;
      height: 80%;
    }
  }
}
