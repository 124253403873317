.document-view-wrapper {
  .document-view {
    margin-bottom: 8px;
  }

  .document-link-wrapper {
    a {
      margin-left: 8px;
    }
  }
}
